
import AppName from '@/components/mixin/AppName.vue';
import TableSearchbar from '@/components/table/TableSearchbar.vue';
import CurrentUser from '@/store/models/CurrentUser';
import { toTitleCase } from '@/utils/FormattingUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Emit, Inject, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ListCardItem from '../cards/ListCardItem.vue';
import MobileContainer from '../common/MobileContainer.vue';
import MobileHeader from '../common/MobileHeader.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import { PartnerRoleWithPermissionDefinitions } from '@/services/api/models/PartnerRole';
import { PermissionService } from '@/services/PermissionService';

@Component({
  components: {
    TableSearchbar,
    ScrollableContainer,
    MobileContainer,
    MobileHeader,
    ListCardItem
  },
  methods: { toTitleCase, isAdmin },
  computed: {
    ...mapGetters('user', {
      currentUser: 'currentUser'
    })
  }
})
export default class UsersCardList extends mixins(AppName) {
  @Prop() users!: CurrentUser[];
  @Prop() searchTerm!: string;
  @Prop() role!: string;

  @Prop({ required: true, type: Array })
  public allRoles!: PartnerRoleWithPermissionDefinitions[];

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public activeSearch = false;

  public get canAddUsers(): boolean {
    return this.permissionService.canAddUsers;
  }

  public get canEditAndRemoveUsers(): boolean {
    return this.permissionService.canEditAndRemoveUsers;
  }

  @Emit('search')
  public search(searchTerm: string) {
    return searchTerm;
  }

  @Emit('updateRoleFilter')
  public updateRoleFilter(role: string) {
    return role;
  }

  @Emit('addNewUser')
  public addNewUser() {
    return;
  }

  @Emit('editUser')
  public editUser(index: number) {
    return index;
  }

  @Emit('deleteUser')
  public deleteUser(index: number) {
    return index;
  }

  public activateSearch() {
    this.activeSearch = true;
  }

  public unactivateSearch() {
    this.activeSearch = false;
    this.search('');
  }
}
