var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"listUserContainer",staticClass:"containerOuter text-left",style:(_vm.getTopPos())},[_c('div',{staticClass:"containerInner border-left border-right mx-4 d-none d-lg-block"},[_c('BRow',{staticClass:"bg-grey mx-0 text-left",staticStyle:{"font-weight":"bold","position":"sticky","top":"0","z-index":"100"}},[_c('BCol',{staticClass:"col240 p-0 pl-3 my-auto py-2",staticStyle:{"padding-left":"16px !important","margin-right":"8px"}},[_c('p',{staticClass:"my-auto"},[_vm._v(" Name ")])]),_c('BCol',{staticClass:"col240 my-auto"},[_c('p',{staticClass:"my-auto"},[_vm._v("Email")])]),_c('BCol',{staticClass:"col160 my-auto"},[_c('p',{staticClass:"my-auto"},[_vm._v("Phone")])]),_c('BCol',{staticClass:"col100 my-auto"},[_c('p',{staticClass:"my-auto"},[_vm._v("Role")])]),_c('BCol',{staticClass:"float-right ml-auto col100 my-auto"},[_c('p',{staticClass:"text-right my-auto"},[_vm._v("Actions")])])],1),(_vm.users || _vm.users.length != 0)?_c('div',{staticClass:"milkListView overflow-y-auto px-3"},_vm._l((_vm.users),function(user,index){return _c('div',{key:index,ref:"list",refInFor:true,attrs:{"border":""}},[_c('BRow',{staticClass:"productListEntity text-left p-2 border-bottom d-flex align-items-center align-self-center",attrs:{"id":'userlist_' + user.id,"align-v":"center"}},[_c('BCol',{staticClass:"col240 d-flex p-0 pl-2"},[_c('p',{staticClass:"my-auto textOverflowEllipsis",attrs:{"title":user.name}},[_vm._v(" "+_vm._s(user.name)+" ")])]),_c('BCol',{staticClass:"col240 my-auto"},[_c('p',{staticClass:"my-auto textOverflowEllipsis",attrs:{"title":user.email}},[_vm._v(" "+_vm._s(user.email)+" ")])]),_c('BCol',{staticClass:"col160 my-auto"},[_c('p',{staticClass:"my-auto textOverflowEllipsis",attrs:{"title":user.phone}},[_vm._v(" "+_vm._s(user.phone)+" ")])]),_c('BCol',{staticClass:"col240 my-auto"},[_c('FormDropdownInput',{attrs:{"inputAttrs":{
                id: 'role',
                label: 'Role',
                value: user.currentRole?.title,
                options: _vm.roleDropdownOptions,
                variant: _vm.bootstrapColour,
                disabled: !_vm.canSetUserRoles || user.id === _vm.currentUser.id
              },"id":"role"},on:{"input":r => _vm.onRoleChange(r, user)}})],1),(user.id != _vm.currentUser.id && _vm.canEditAndRemoveUsers)?_c('BCol',{staticClass:"col40 my-auto float-right ml-auto",on:{"click":function($event){return _vm.deleteUser(index)}}},[_c('p',{staticClass:"my-auto"},[_c('BIcon',{attrs:{"icon":"trash","scale":"1.3","variant":"mediumGrey"}})],1)]):_vm._e(),(_vm.canEditAndRemoveUsers)?_c('BCol',{staticClass:"my-auto col40 pr-2 float-right",class:user.id == _vm.currentUser.id ? 'ml-auto' : '',on:{"click":function($event){return _vm.editUser(index)}}},[_c('p',{staticClass:"my-auto"},[_c('BIcon',{attrs:{"icon":"pencil","scale":"1.3","variant":"mediumGrey"}})],1)]):_vm._e()],1)],1)}),0):_vm._e()],1),_c('UsersCardList',{staticClass:"d-lg-none",attrs:{"users":_vm.users,"searchTerm":_vm.searchTerm,"role":_vm.role,"allRoles":_vm.allRoles},on:{"editUser":_vm.editUser,"deleteUser":_vm.deleteUser,"search":_vm.search,"addNewUser":_vm.addNewUser,"updateRoleFilter":_vm.updateRoleFilter}}),(_vm.users && _vm.users.length > 0)?_c('BModal',{ref:"modal",staticClass:"d-flex",attrs:{"id":"editUserData","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":"editFormData___BV_modal_title_"}},[_vm._v(" "+_vm._s(`Edit ${_vm.users[_vm.selectedUser].name}'s details`)+" ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" × ")])]},proxy:true}],null,false,33558999)},[_c('BForm',{attrs:{"onsubmit":"return false"},on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v("Edit user details")]),_c('FormTextInput',{attrs:{"inputAttrs":{
          id: 'name',
          label: 'Name',
          labelClass: 'text-primary',
          type: 'text',
          value: _vm.users[_vm.selectedUser].name,
          variant: _vm.bootstrapColour
        },"id":"name"},model:{value:(_vm.editUserField.name),callback:function ($$v) {_vm.$set(_vm.editUserField, "name", $$v)},expression:"editUserField.name"}}),_c('FormTextInput',{attrs:{"inputAttrs":{
          id: 'email',
          label: 'Email',
          labelClass: 'text-primary',
          type: 'text',
          value: _vm.users[_vm.selectedUser].email,
          variant: _vm.bootstrapColour
        },"id":"email"},model:{value:(_vm.editUserField.email),callback:function ($$v) {_vm.$set(_vm.editUserField, "email", $$v)},expression:"editUserField.email"}}),_c('PhoneNumberInput',{attrs:{"inputAttrs":{
          phone: _vm.users[_vm.selectedUser].phone
        }},model:{value:(_vm.editUserField.phone),callback:function ($$v) {_vm.$set(_vm.editUserField, "phone", $$v)},expression:"editUserField.phone"}}),(_vm.users[_vm.selectedUser].id != _vm.currentUser.id)?_c('FormDropdownInput',{attrs:{"inputAttrs":{
          id: 'role',
          label: 'Role',
          value: _vm.users[_vm.selectedUser].currentRole?.title,
          options: _vm.roleDropdownOptions,
          variant: _vm.bootstrapColour,
          disabled: !_vm.canSetUserRoles
        },"id":"role"},model:{value:(_vm.editUserField.role),callback:function ($$v) {_vm.$set(_vm.editUserField, "role", $$v)},expression:"editUserField.role"}}):_vm._e(),_c('BButton',{staticClass:"d-flex mx-auto text-white mt-4 px-5",attrs:{"type":"submit","variant":_vm.bootstrapColour}},[_vm._v("Save")])],1)],1):_vm._e(),_c('BModal',{ref:"deleteUserModal",staticClass:"d-flex",attrs:{"id":"deleteUserModal","title":`Are you sure you want to delete ${
        _vm.users[_vm.selectedUser] ? _vm.users[_vm.selectedUser].name : ''
      }`,"centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":"deleteUserModal___BV_modal_title_"}},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.users[_vm.selectedUser] ? _vm.users[_vm.selectedUser].name : '')+" ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeDeleteModal()}}},[_vm._v(" × ")])]},proxy:true}])},[_c('BRow',[_c('BCol',[_c('BButton',{staticClass:"d-flex mx-auto float-right text-white px-5",attrs:{"variant":_vm.bootstrapColour},on:{"click":function($event){return _vm.confirmDeleteUser()}}},[_vm._v("Delete")])],1),_c('BCol',[_c('BButton',{staticClass:"d-flex float-left mx-auto px-5",attrs:{"variant":`outline-${_vm.bootstrapColour}`},on:{"click":function($event){return _vm.closeDeleteModal()}}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }